

import React from 'react';
import Enterprise from 'views/EnrichmentProgrammes';

const EnterprisePage = () => {
  return <Enterprise />;
};

export default EnterprisePage;
